<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
   
    <v-layout wrap justify-center >
      <!-- <v-flex v-if="$route.query.session=='null'" pt-5>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
        <v-layout wrap justify-center>
          <v-flex xs12 pa-2 align-self-center>
            <span class="nsbold" style="font-size:18px">No present student marked today</span>
          </v-flex>
        </v-layout>
        </v-card>
      </v-flex> -->
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-layout wrap class="pb-4">
            <v-flex xs12 md5 lg8 text-md-left text-left>
              <span class="nsbold" style="font-size: 18px"
                >Absent Student List</span
              >
            </v-flex>

            <v-flex xs12 md3 lg3>
              <v-text-field
                v-model="keyword"
                dense
                @keyup="getData()"
                class="rounded-xl"
                hide-details
                label="Search Name/Ad.No"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 md3 lg1 pt-1 pl-1>
              <v-btn
              @click="getData()"
              style="font-size: 16px; font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              class="rounded-xl"
              depressed
              
              >SEARCH</v-btn
            >
            </v-flex> -->
            <v-flex xs11 md3 lg1 text-md-right text-left align-self-center>
              <v-btn small plain>
                <v-icon
                  @click="$router.go(-1)"
                  title="Back"
                  style="cursor: pointer; color: #766bc0"
                  >mdi-arrow-left</v-icon
                >
              </v-btn></v-flex
            >
          </v-layout>
          <!------------------------Filters------------------------------->
          <!-- <v-layout wrap justify-end>
            <v-flex xs12 sm3 md2 lg2 pr-1 v-if="appUser.usertype == 1">
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                class="rounded-xl"
                item-text="hostelName"
                item-value="_id"
                clearable
                hide-details
                @input="getHostelWarden()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1 v-if="appUser.usertype == 1">
              <v-select
                :items="hostelwardenList"
                v-model="hostelwarden"
                label="Warden"
                class="rounded-xl"
                item-text="name"
                item-value="_id"
                clearable
                hide-details
                @input="getBlockid()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1>
              <v-select
                :items="BlockIds"
                v-model="hostelblockid"
                label="Select Block"
                class="rounded-xl"
                item-text="Block"
                item-value="_id"
                hide-details
                outlined
                dense
              ></v-select>
            </v-flex>
          </v-layout> -->
          <!--------------------------list------------------------>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="absentList">
              <v-layout wrap>
                <v-flex xs12 v-if="absentList.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admn.No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <!-- <th class="text-left">Division</th> -->
                          <th class="text-left">Profile</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>{{attendance}}</tr>  -->
                        <tr v-for="(item, i) in absentList" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 50 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{ item.admissionNo }}</td>

                          <td>{{ item.name }}</td>
                          <td>
                            <span v-if="item.studentclass">
                              {{ item.studentclass.className }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.studentid.division">
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->

                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item._id"
                            >
                              <v-icon style="cursor: pointer; color: #766bc0">
                                mdi-eye
                              </v-icon>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      absentList: [],
      testid: null,
      // HostelList: [],
      // hostel: null,
      // hostelwardenList: [],
      // hostelwarden: null,
      // BlockIds: [],
      // hostelblockid: null,
    };
  },
  mounted() {
    // if (this.fromDate) {
    //   var dt = new Date();

    //   dt.setDate(dt.getDate() - 30 + 1);
    //   this.fromDate = dt.toISOString().substr(0, 10);
    // }
    // if (this.from) {
    //   var dlt = new Date();

    //   dlt.setDate(dlt.getDate() - 30 + 1);
    //   this.fromDate = dlt.toISOString().substr(0, 10);
    // }
    // if(this.appUser.usertype==1){
    //   this.getData2();
    // }
    // else{
    this.getData();
    // }
  },
  computed: {
    appUser() {
      return store.state.userData;
    },
  },
  watch: {
    page() {
      this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    // hostel() {
    //   if (this.page > 1) this.page = 1;
    //   this.getData();
    // },
    // hostelwarden() {
    //   if (this.page > 1) this.page = 1;
    //   this.getData();
    // },
    // hostelblockid() {
    //   if (this.page > 1) this.page = 1;
    //   this.getData();
    // },
  },
  methods: {
    getData() {
      this.appLoading = true;
      // if (this.$route.query.id == "undefined") {
      //   this.testid = null;
      // } else {
      //   this.testid = this.$route.query.id;
      // }
      axios({
        method: "post",
        url: "/todays/absent/student/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: this.page,
          limit: 50,
          keyword: this.keyword,
             mainHostelId: this.hostel,
          wardenId: this.hostelwarden,
          blockId:this.hostelblockid,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.absentList = response.data.absentList;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     getData2() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "todays/all/hostel/presentcount",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
           this.countData=response.data.presentdata;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
       gethostel() {
      axios({
        method: "get",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;

          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getHostelWarden() {
      this.hostelwarden = null,
      this.hostelblockid = null,
      this.appLoading = true;
      axios({
        method: "get",
        url: "hostel/warden/getlist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hostelwardenList = response.data.data;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     getBlockid() {
      this.hostelblockid = null,
      this.appLoading = true;
      axios({
        method: "post",
        url: "/warden/hostelblock/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data:{
          wardenid:this.hostelwarden
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            if(response.data.hosteldata){
              if(response.data.hosteldata.length>0){
           this.BlockIds = response.data.hosteldata;
           if(this.usertype1==true){
            this.hostelblockid = response.data.hosteldata[0]._id;
           this.getData();
           }
              }
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>

